import React from "react";
import Layout from "../components/Layout";
import Paper from "../components/Paper";

const Page = () => (
  <Layout fullgreen title="Become a Supplier">
    <Paper>
      <div className="page-header">
        <h1>Whant to list your items?</h1>
        <p>Get more orders today.</p>
      </div>
      <div className="page-content text-center">
        <div className="row">
          <div className="col-md-10 offset-md-1 col-xxl-8 offset-xxl-2">
            <p>
              We are constantly looking for new suppliers with high quality
              procuts and good level of service. If you think that you one of
              those kind, please drop us a line on{" "}
              <a href="mailto:business@supplyme.ae">business@supplyme.ae</a>,
              one of our team member will get in touch with you for onboarding.
            </p>

            <p className="mt-5">
              <a
                href="mailto:business@supplyme.ae"
                className="btn btn-primary btn-lg"
              >
                Join Us Today
              </a>
            </p>
          </div>
        </div>
      </div>
    </Paper>
  </Layout>
);

export default Page;
